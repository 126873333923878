import React, { ReactElement, ReactNode } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Container } from "@mui/material";
import NuxTopNav from "../nux/components/layout/NuxTopNav";
import NuxFooter from "../nux/components/layout/NuxFooter";
import NuxUCHome from "../nux/components/home/NuxUCHome";
import { AccountInsightClientProvider } from "../client/AccountInsightClient";
import { Language } from "../nux/components/buttons/NuxLanguageSelector";
import IdleAlert from "../nux/niam/IdleAlert";
import { useAuth } from "../nux/niam/auth";

export interface DefaultLayoutProps {
    env?: string;
    children: ReactNode;
}

const DefaultLayout = ({ env, children }: DefaultLayoutProps) => {
    const auth = useAuth();
    const menuItems: ReactElement<typeof MenuItem>[] = [];

    const home: ReactNode = <NuxUCHome title="Account Insight" />;

    return (
        <AccountInsightClientProvider>
            {auth.authenticated && (
                <IdleAlert
                    idleTimeoutInSeconds={10 * 60}
                    countdownTimeInSeconds={3 * 60}
                />
            )}
            <NuxTopNav
                home={home}
                menuItems={menuItems}
                languages={[Language.SV, Language.EN, Language.FI]}
                env={env}
            />
            <Container
                fixed
                maxWidth="lg"
                sx={{
                    backgroundColor: "#F7F7F7",
                    marginTop: "20px",
                    minWidth: "fit-content",
                }}
            >
                <main>{children}</main>
            </Container>
            <footer>
                <NuxFooter />
            </footer>
        </AccountInsightClientProvider>
    );
};

export default DefaultLayout;
