import { NiamService, User } from "./NiamAuthTypes";

export enum AuthAction {
    UNAUTHORIZED = "UNAUTHORIZED",
    AUTHORIZING = "UNAUTHORIZED",
    AUTHORIZED = "AUTHORIZED",
    COMPLETED = "COMPLETED",
}

export class AuthState {
    static init(niamService: NiamService | null): AuthState {
        return new AuthState(
            AuthAction.UNAUTHORIZED,
            niamService,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
    }

    authAction: AuthAction;
    niamService: NiamService | null;
    user: User | null;
    error: string | null;
    code: string | null;
    accessToken: string | null;
    idToken: string | null;
    expiresIn: number | null;
    refreshToken: string | null;

    constructor(
        authAction: AuthAction,
        niamService: NiamService | null,
        user: User | null,
        error: string | null,
        code: string | null,
        accessToken: string | null,
        idToken: string | null,
        expiresIn: number | null,
        refreshToken: string | null
    ) {
        this.authAction = authAction;
        this.niamService = niamService;
        this.user = user;
        this.error = error;
        this.code = code;
        this.accessToken = accessToken;
        this.idToken = idToken;
        this.expiresIn = expiresIn;
        this.refreshToken = refreshToken;
    }

    updateError(error: string) {
        return new AuthState(
            AuthAction.UNAUTHORIZED,
            this.niamService,
            null,
            error,
            null,
            null,
            null,
            null,
            null
        );
    }

    initiateAuthorization(code: string) {
        return new AuthState(
            AuthAction.AUTHORIZING,
            this.niamService,
            null,
            null,
            code,
            null,
            null,
            null,
            null
        );
    }

    setNiamService(niamService: NiamService | undefined) {
        return new AuthState(
            this.authAction,
            niamService || this.niamService,
            this.user,
            this.error,
            this.code,
            this.accessToken,
            this.idToken,
            this.expiresIn,
            this.refreshToken
        );
    }

    updateTokens(
        accessToken: string,
        idToken: string,
        expiresIn: number,
        refreshToken: string | null
    ) {
        return new AuthState(
            AuthAction.AUTHORIZED,
            this.niamService,
            null,
            null,
            null,
            accessToken,
            idToken,
            expiresIn,
            refreshToken
        );
    }

    updateUser(user: User) {
        return new AuthState(
            AuthAction.COMPLETED,
            this.niamService,
            user,
            null,
            null,
            this.accessToken,
            this.idToken,
            this.expiresIn,
            this.refreshToken
        );
    }

    reset(): AuthState {
        return new AuthState(
            AuthAction.UNAUTHORIZED,
            this.niamService,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
    }
}
