import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
    AccessTokenResponse,
    AuthProviderProps,
    NiamService,
    UseAuth,
    UseProvideAuthProps,
    UserInfoResponse,
    UserPermission,
} from "./NiamAuthTypes";
import { generatePKCE } from "./authUtil";
import {
    fetchAuthUser,
    fetchAuthWebAccessToken,
    fetchRefreshedAccessToken,
} from "./NiamAuthClient";
import { AuthAction, AuthState } from "./AuthState";
import * as jose from "jose";
import config from "../../config/config";

const defaultAuthContext = {
    authenticated: false,
    user: null,
    accessToken: null,
    idToken: null,
    error: null,
    resetLogin: () => {},
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    hasPermission: (permission: string) => false,
};

const AuthContext = createContext<UseAuth>(defaultAuthContext);

export const AuthProvider: FC<AuthProviderProps> = ({
    children,
    authorizeUrl,
    deAuthorizeUrl,
    tokenUrl,
    userInfoUrl,
    clientId,
    scopes,
    redirectUrl,
    service,
    permissions,
}) => {
    const auth = useProvideAuth({
        authorizeUrl,
        deAuthorizeUrl,
        tokenUrl,
        userInfoUrl,
        clientId,
        redirectUrl,
        scopes,
        service,
        permissions,
    });
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

function createDisplayNameFromUserInfo(
    userInfoResponse: UserInfoResponse
): string {
    if (userInfoResponse.given_name && userInfoResponse.family_name) {
        return userInfoResponse.given_name + " " + userInfoResponse.family_name;
    }
    if (userInfoResponse.given_name) return userInfoResponse.given_name;
    if (userInfoResponse.family_name) return userInfoResponse.family_name;
    if (userInfoResponse.email) return userInfoResponse.email;
    return "Unknown";
}

function getAuthorizationUrl(
    service: undefined | NiamService,
    authorizeUrl: string,
    tokenUrl: string
): string {
    return service == NiamService.AADLogin
        ? tokenUrl.substring(0, tokenUrl.lastIndexOf("/") + 1) + "/authorize"
        : authorizeUrl;
}

const useProvideAuth = ({
    authorizeUrl,
    deAuthorizeUrl,
    clientId,
    scopes = [],
    redirectUrl,
    tokenUrl,
    service,
    userInfoUrl,
    permissions = [],
}: UseProvideAuthProps): UseAuth => {
    const [authState, setAuthState] = useState<AuthState>(
        AuthState.init(service || null)
    );
    const codeVerifier = sessionStorage.getItem("codeVerifier");
    const { code } = useQueryParams();
    const minutesBeforeExpirationThreshold = 5;

    //console.log("useProvideAuth state=" + authState.authAction)

    const verifyPermissions = useCallback(
        (userPermissions: UserPermission[]) => {
            const mappedUserPermissions = userPermissions.map(
                (userPermission) => userPermission.name
            );
            const hasAllPermissions = permissions.every((permission) =>
                mappedUserPermissions.includes(permission)
            );
            if (!hasAllPermissions) {
                resetLogin("FORBIDDEN_ACCESS");
            }
        },
        [permissions]
    );

    // Initiate state from session storage
    useEffect(() => {
        const storedAccessToken = sessionStorage.getItem("accessToken");
        const storedIdToken = sessionStorage.getItem("idToken");
        const refreshToken = sessionStorage.getItem("refreshToken");
        const expiresIn = Number(sessionStorage.getItem("expiresIn"));
        if (
            (storedIdToken && isTokenExpired(storedIdToken)) ||
            (storedAccessToken && isTokenExpired(storedAccessToken))
        ) {
            console.log("useProvideAuth loaded tokens have expired REMOVING");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("idToken");
            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("expiresIn");
            return;
        }
        if (storedAccessToken && storedIdToken && !code) {
            console.log("useProvideAuth loaded tokens from session storage");
            setAuthState((prevAuthState) =>
                prevAuthState.updateTokens(
                    storedAccessToken,
                    storedIdToken,
                    expiresIn,
                    refreshToken
                )
            );
        }
    }, []);

    // challenge callback from NIAM with code to use in actual login
    useEffect(() => {
        if (
            !authState.code ||
            authState.authAction !== AuthAction.AUTHORIZING ||
            !codeVerifier
        ) {
            return;
        }
        const fetchAccessToken = async (
            code: string,
            codeVerifier: string,
            retry: boolean
        ) => {
            try {
                const accessTokenResponse = await fetchAuthWebAccessToken(
                    tokenUrl,
                    code,
                    clientId,
                    codeVerifier,
                    redirectUrl
                );
                //console.log("useProvideAuth.fetchAccessToken accessTokenResponse",accessTokenResponse)
                if (!accessTokenResponse) {
                    console.log(
                        "useProvideAuth.fetchAccessToken FAILED accessTokenResponse EMPTY!"
                    );
                    setAuthState((prevAuthState) =>
                        prevAuthState.updateError("Unknown error")
                    );
                    return;
                }
                updateToken(accessTokenResponse);
            } catch (e: any) {
                if (retry) {
                    console.error(
                        "useProvideAuth RETRY in 500ms. Failed to retrieve access token because ",
                        e
                    );
                    setTimeout(
                        () => fetchAccessToken(code, codeVerifier, false),
                        2000
                    );
                    return;
                }
                console.error(
                    "useProvideAuth Failed to retrieve access token because ",
                    e
                );
                setAuthState((prevAuthState) =>
                    prevAuthState.updateError(e.message)
                );
            }
        };
        fetchAccessToken(authState.code, codeVerifier, true);
    }, [authState.code, authState.authAction]);

    const updateToken = (accessTokenResponse: AccessTokenResponse) => {
        sessionStorage.setItem("accessToken", accessTokenResponse.access_token);
        sessionStorage.setItem("idToken", accessTokenResponse.id_token);
        sessionStorage.setItem(
            "expiresIn",
            String(accessTokenResponse.expires_in)
        );
        if (accessTokenResponse.refresh_token) {
            sessionStorage.setItem(
                "refreshToken",
                accessTokenResponse.refresh_token
            );
            console.log(
                "Refresh token received. Will update token automatically within 60 min."
            );
        } else
            console.log(
                "No Refresh token received. No automatic updates will be possible."
            );
        sessionStorage.removeItem("codeVerifier");
        setAuthState((prevAuthState) =>
            prevAuthState.updateTokens(
                accessTokenResponse.access_token,
                accessTokenResponse.id_token,
                accessTokenResponse.expires_in,
                accessTokenResponse.refresh_token
            )
        );
    };

    useEffect(() => {
        if (
            !code ||
            authState.authAction !== AuthAction.UNAUTHORIZED ||
            !codeVerifier
        ) {
            return;
        }
        setAuthState((prevAuthState) =>
            prevAuthState.initiateAuthorization(code)
        );
    }, [code, authState.authAction]);

    // on refresh token change, set timer for renewal
    useEffect(() => {
        if (authState.refreshToken && authState.expiresIn) {
            const refreshTime =
                (authState.expiresIn - minutesBeforeExpirationThreshold * 60) *
                1000;
            console.log(
                `Token will be refreshed at ${new Date(
                    Date.now() + refreshTime
                )}`
            );
            const timer = setTimeout(refreshAccessToken, refreshTime);
            return () => clearTimeout(timer);
        }
    }, [authState.refreshToken]);

    const refreshAccessToken = () => {
        console.log("Token about to expire. Renewing");

        if (!authState.refreshToken || authState.accessToken == null) {
            return;
        }
        fetchRefreshedAccessToken(
            tokenUrl,
            authState.refreshToken,
            clientId
        ).then((accessTokenResponse) => {
            if (!accessTokenResponse) {
                console.log(
                    "useProvideAuth.refreshAccessToken FAILED accessTokenResponse EMPTY!"
                );
                setAuthState((prevAuthState) =>
                    prevAuthState.updateError("Unknown error")
                );
                return;
            }
            updateToken(accessTokenResponse);
        });
    };

    // Fetch user and permissions, when authorized
    useEffect(() => {
        if (
            authState.authAction !== AuthAction.AUTHORIZED ||
            authState.accessToken === null
        ) {
            //console.log("useProvideAuth.login fetchUser IGNORED because action="+authState.authAction+" accessToken="+authState.accessToken)
            return;
        }
        const fetchUser = async (accessToken: string) => {
            try {
                const userInfoResponse = await fetchAuthUser(
                    userInfoUrl,
                    accessToken
                );
                if (!userInfoResponse) {
                    console.log("useProvideAuth.login fetchUser FAILED");
                    return;
                }
                const userPermissions: UserPermission[] =
                    userInfoResponse.permissions.map((permission) =>
                        permission.name != undefined
                            ? permission
                            : { name: permission }
                    );

                setAuthState((prevAuthState) =>
                    prevAuthState.updateUser({
                        sub: userInfoResponse.sub,
                        firstName: userInfoResponse.given_name,
                        lastName: userInfoResponse.family_name,
                        displayName:
                            createDisplayNameFromUserInfo(userInfoResponse),
                        email: userInfoResponse.email,
                        permissions: userPermissions,
                    })
                );
                verifyPermissions(userPermissions);
            } catch (e: any) {
                console.error(
                    "useProvideAuth Failed to retrieve permissions because ",
                    e
                );
                console.log("useProvideAuth userInfoUrl " + userInfoUrl);
                //console.log("useProvideAuth accessToken "+accessToken)
                setAuthState((prevAuthState) =>
                    prevAuthState.updateError(e.message)
                );
            }
        };

        fetchUser(authState.accessToken);
    }, [authState.authAction]);

    const resetLogin = (error?: string) => {
        sessionStorage.removeItem("idToken");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("expiresIn");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("codeVerifier");
        setAuthState((prevAuthState) => authState.reset());
    };

    const login = async (niamService?: NiamService) => {
        if (authState.authAction !== AuthAction.UNAUTHORIZED) {
            console.log("Cannot login! Authorization in progress!", authState);
            return;
        }
        if (!niamService) {
            if (authState.niamService == null)
                throw new Error("Unable to login! Missing niamService");
            niamService = authState.niamService;
        }

        niamCodeChallenge(
            getAuthorizationUrl(niamService, authorizeUrl, tokenUrl),
            clientId,
            redirectUrl,
            niamService,
            scopes.join(" "),
            config.ENVIRONMENT
        );
    };

    const logout = async () => {
        if (!authState.accessToken || !authState.idToken) {
            console.log("missing token to logout");
            return;
        }
        await niamDeAuthorize(
            deAuthorizeUrl,
            authState.idToken,
            authState.accessToken
        );
        resetLogin();
    };

    const hasPermission = (permission: string): boolean => {
        return (
            !!permission &&
            !!authState.user?.permissions.some((userPermission) =>
                permission.includes(userPermission.name)
            )
        );
    };

    return {
        authenticated: !!authState.accessToken,
        user: authState.user,
        accessToken: authState.accessToken,
        idToken: authState.idToken,
        resetLogin,
        login,
        logout,
        error: authState.error,
        hasPermission,
    };
};

const niamCodeChallenge = async (
    authorizeUrl: string,
    clientId: string,
    redirectUrl: string,
    service: string,
    scope: string,
    state?: string
) => {
    const { verifier, challenge } = await generatePKCE();
    sessionStorage.setItem("codeVerifier", verifier);
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("expiresIn");
    sessionStorage.removeItem("refreshToken");
    window.location.href = `${authorizeUrl}?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUrl}&service=${service}&code_challenge_method=S256&code_challenge=${challenge}${
        state ? `&state=${state}` : ""
    }`;
};

const niamDeAuthorize = async (
    deAuthorizeUrl: string,
    idToken: string,
    accessToken: string
) => {
    try {
        const response = await fetch(
            `${deAuthorizeUrl}?id_token_hint=${idToken}`,
            { headers: getAuthHeader(accessToken) }
        );
        if (response.status === 204) {
            console.log("successfully logged out");
        }
    } catch (e) {
        console.error(e);
    }
};

const getAuthHeader = (accessToken: string | null): { [key: string]: string } =>
    accessToken
        ? {
              Authorization: `Bearer ${accessToken}`,
          }
        : {};

const useQueryParams = () => {
    const [searchParams] = useSearchParams();
    return {
        code: searchParams.get("code"),
        iss: searchParams.get("iss"),
        clientId: searchParams.get("client_id"),
    };
};

export const isTokenExpired = (token: string): boolean => {
    try {
        const { exp } = jose.decodeJwt(token);
        if (!exp) return true;
        const expirationDatetimeInSeconds = exp * 1000;
        return Date.now() >= expirationDatetimeInSeconds;
    } catch {
        return true;
    }
};

export const clearSessionAndLogout = () => {
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("codeVerifier");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("expiresIn");
    window.location.reload();
};
