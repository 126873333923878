import React from "react";
import { Route } from "react-router-dom";
import DetailsPage from "./DetailsPage";

export const DetailsPageRoute = (
    <Route key="/details/:id" path="/details/:id" element={<DetailsPage />} />
);

export function detailsPageNavigationPath(id: string) {
    return `/details/${id}`;
}
