import React from "react";

const NuxFooter = () => {
    return (
        <div style={{ backgroundColor: "#F7F7F7" }}>
            <h3>&nbsp;</h3>
        </div>
    );
};

export default NuxFooter;
