import { Config } from "../nux";

const configResolver = (): Config => {
    return new (class implements Config {
        ENVIRONMENT =
            process.env.REACT_APP_ENVIRONMENT ||
            "REACT_APP_ENVIRONMENT_PLACEHOLDER";
        NIAM_URL =
            process.env.REACT_APP_NIAM_URL || "REACT_APP_NIAM_URL_PLACEHOLDER"; // https://login.niam.enento.com/am/oauth2/customers';
        NIAM_URL_ACCESSTOKEN =
            process.env.REACT_APP_NIAM_URL_ACCESSTOKEN ||
            "REACT_APP_NIAM_URL_ACCESSTOKEN_PLACEHOLDER"; // https://login.niam.enento.com/am/oauth2/customers';
        NIAM_REDIRECT_URL =
            process.env.REACT_APP_NIAM_REDIRECT_URL ||
            "REACT_APP_NIAM_REDIRECT_URL_PLACEHOLDER"; // 'https://consumer-accountinsight-client-prod-mod1.apps.ocp01.softronic.se/';
        TARGET_URL =
            process.env.REACT_APP_TARGET_URL ||
            "REACT_APP_TARGET_URL_PLACEHOLDER"; // 'https://consumer-accountinsight-prod-mod1.apps.ocp01.softronic.se/apis/insight';
        SENTRY_DSN =
            process.env.REACT_APP_SENTRY_DSN ||
            "REACT_APP_SENTRY_DSN_PLACEHOLDER"; // 'https://consumer-accountinsight-prod-mod1.apps.ocp01.softronic.se/apis/insight';
    })();
};

export default configResolver();
