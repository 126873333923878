import React, { createContext, FC, useContext, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

export interface NuxDialogUsages {
    showConfirmDialog(title: string, message: string): Promise<void>;
}

const DefaultNuxDialogUsages: NuxDialogUsages = {
    showConfirmDialog(title: string, message: string): Promise<void> {
        return Promise.reject("Un-Initialized!");
    },
};

const ConfirmDialogContext = createContext<NuxDialogUsages>(
    DefaultNuxDialogUsages
);

interface ConfirmDialogProviderProps {
    children: React.ReactNode[] | React.ReactNode;
}

enum DialogType {
    CONFIRM = "CONFIRM",
}

interface DialogState {
    type: DialogType;
    title: string;
    message: string;
    confirm: () => void;
    reject: () => void;
}

export const ConfirmDialogProvider: FC<ConfirmDialogProviderProps> = ({
    children,
}) => {
    const [dialogState, setDialogState] = useState<DialogState | null>(null);

    const showConfirmDialog = (
        title: string,
        message: string
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            setDialogState({
                type: DialogType.CONFIRM,
                title,
                message,
                confirm: () => resolve(),
                reject: () => reject(),
            });
        });
    };

    const hideConfirmDialog = () => {
        setDialogState((prevState) => null);
    };

    const handleConfirm = () => {
        if (dialogState?.confirm) dialogState.confirm();
        hideConfirmDialog();
    };

    const nuxDialogUsages: NuxDialogUsages = {
        showConfirmDialog,
    };

    return (
        <ConfirmDialogContext.Provider value={nuxDialogUsages}>
            {children}
            <ConfirmDialog
                open={dialogState?.type === DialogType.CONFIRM}
                title={dialogState ? dialogState?.title : ""}
                message={dialogState ? dialogState?.message : ""}
                onConfirm={handleConfirm}
                onReject={hideConfirmDialog}
            />
        </ConfirmDialogContext.Provider>
    );
};

type ConfirmDialog = {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onReject: () => void;
};

const ConfirmDialog: FC<ConfirmDialog> = ({
    open,
    onConfirm,
    onReject,
    title,
    message,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Dialog open={open} onClose={onReject}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onReject()}
                        color="primary"
                        startIcon={<CancelIcon />}
                    >
                        {t("cancelButtonCaption", { ns: "nux" })}
                    </Button>
                    <Button
                        onClick={() => onConfirm()}
                        color="secondary"
                        variant="contained"
                        startIcon={<CheckIcon />}
                    >
                        {t("confirmButtonCaption", { ns: "nux" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const useNuxDialog = () => {
    return useContext(ConfirmDialogContext);
};
