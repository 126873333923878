import React from "react";
import { useTranslation } from "react-i18next";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from "@mui/material";
import { NiamService } from "../../niam/NiamAuthTypes";
import NuxButton from "./NuxButton";
import PasswordIcon from "@mui/icons-material/Password";

interface SelectAuthenticationMethodDialogProps {
    open: boolean;

    onClose(): void;

    onSelectAuthenticationMethod(niamService: NiamService): void;

    loginMethods: NiamService[];
}

function GetLogonMethods(
    methods: NiamService[],
    selectMethod: (method: NiamService) => void
) {
    const { t } = useTranslation();

    return (
        <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">
            {methods.map((method: NiamService) => (
                <NuxButton
                    key={method}
                    data-testid="do-select-{method}"
                    color="primary"
                    onClick={() => selectMethod(method)}
                    variant="contained"
                >
                    <PasswordIcon />
                    &nbsp;
                    {t(
                        "selectAuthenticationDialog" +
                            NiamService[method] +
                            "ButtonCaption",
                        { ns: "nux" }
                    )}
                </NuxButton>
            ))}
        </Stack>
    );
}

export const SelectAuthenticationMethodDialog = ({
    open,
    onClose,
    onSelectAuthenticationMethod,
    loginMethods,
}: SelectAuthenticationMethodDialogProps) => {
    const { t } = useTranslation();

    return (
        <Dialog onClose={(event) => onClose()} open={open}>
            <DialogTitle>
                {t("selectAuthenticationDialogTitle", { ns: "nux" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("selectAuthenticationDialogCaption", { ns: "nux" })}
                    <br />
                    <br />
                    <br />
                </DialogContentText>
                {GetLogonMethods(loginMethods, onSelectAuthenticationMethod)}
            </DialogContent>
            <DialogActions>
                <NuxButton
                    data-testid="cancel-select-authentication-method"
                    onClick={() => onClose()}
                    color="secondary"
                    variant="outlined"
                >
                    {t("dialogCancel")}
                </NuxButton>
            </DialogActions>
        </Dialog>
    );
};
