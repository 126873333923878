import { PersonDetails } from "./PersonDetails";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
} from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import CheckIcon from "@mui/icons-material/Check";
import NuxButton from "../../../nux/components/buttons/NuxButton";
import { AccountInsightApiError } from "../../../client/AccountInsightClient";

interface CreateNewAccountInsightSessionProps {
    onCreateNewAccountInsightSession(
        personDetails: PersonDetails
    ): Promise<void>;
}

export const CreateNewAccountInsightSessionDialog = ({
    onCreateNewAccountInsightSession,
}: CreateNewAccountInsightSessionProps) => {
    const { t } = useTranslation();
    const [personDetails, setPersonDetails] = useState<
        PersonDetails | undefined
    >(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (message)
            setTimeout(() => {
                setMessage(undefined);
            }, 3000);
    }, [message]);

    const handleClose = () => {
        setPersonDetails(undefined);
    };

    const handleOpen = () => {
        setPersonDetails(PersonDetails.empty());
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleEntered = (focusEvent: React.FocusEvent<HTMLDivElement>) => {
        if (focusEvent.target.id && focusEvent.target.id !== "personId") return;
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleCreateRequest = () => {
        if (!personDetails) return;
        let personDetailsValidated = personDetails.validateAll();
        if (personDetailsValidated.hasErrors()) {
            setPersonDetails(personDetailsValidated);
            return;
        }
        onCreateNewAccountInsightSession(personDetails)
            .then((value) => setPersonDetails(undefined))
            .catch((reason) => {
                console.log("Error: ", reason);
                if (reason instanceof AccountInsightApiError) {
                    switch (reason.apiErrorCode) {
                        case "LEGAL_NOT_FOUND":
                        case "INVALID_FIELD_LEGAL_ID_NULL":
                        case "INVALID_FIELD_LEGAL_ID_FORMAT":
                        case "INVALID_FIELD_LEGAL_ID_CHECKSUM":
                            setPersonDetails(
                                personDetails.setPersonIdError(reason.message)
                            );
                            break;
                        default:
                            setMessage(reason.message);
                    }
                } else if (reason instanceof Error) {
                    setMessage(reason.message);
                } else alert("Unknown error '" + reason + "'");
            });
    };

    const handleSetPersonId = (personId: string) => {
        setPersonDetails((prevPersonDetails) =>
            prevPersonDetails?.changePersonId(personId)
        );
    };

    const handleSetName = (name: string) => {
        setPersonDetails((prevPersonDetails) =>
            prevPersonDetails?.changeName(name)
        );
    };

    const handleSetEmail = (email: string) => {
        setPersonDetails((prevPersonDetails) =>
            prevPersonDetails?.changeEmail(email)
        );
    };

    const handleShouldSendEmail = (shouldSendEmail: boolean) => {
        setPersonDetails((prevPersonDetails) => {
            const updatedDetails =
                prevPersonDetails?.changeShouldSendEmail(shouldSendEmail);
            return shouldSendEmail
                ? updatedDetails
                : updatedDetails?.validateEmail();
        });
    };

    const invalid: boolean = personDetails ? personDetails.hasErrors() : true;

    return (
        <>
            <NuxButton
                data-testid="open-create-insight-session-request"
                variant="contained"
                onClick={handleOpen}
            >
                <InsightsIcon />
                &nbsp;{t("createNewAccountInsightRequestButtonCaption")}
            </NuxButton>
            <Dialog
                onClose={handleClose}
                open={!!personDetails}
                onFocus={(v) => handleEntered(v)}
            >
                <DialogTitle>{t("createRequest")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("createRequestContent")}
                    </DialogContentText>
                    {message && (
                        <Alert
                            icon={<CheckIcon fontSize="inherit" />}
                            severity="success"
                        >
                            {message}
                        </Alert>
                    )}
                    <TextField
                        data-testid="insight-session-request-personId"
                        autoFocus
                        required
                        margin="dense"
                        id="personId"
                        name="personId"
                        label={t("personId")}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={personDetails?.personId}
                        onChange={(event) =>
                            handleSetPersonId(event.currentTarget.value)
                        }
                        helperText={
                            personDetails && personDetails.personIdError
                                ? t(personDetails.personIdError)
                                : ""
                        }
                        error={!!personDetails?.personIdError}
                        inputRef={inputRef}
                    />
                    <TextField
                        data-testid="insight-session-request-personName"
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label={t("personName")}
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={personDetails?.name}
                        onChange={(event) =>
                            handleSetName(event.currentTarget.value)
                        }
                        helperText={
                            personDetails && personDetails.nameError
                                ? t(personDetails.nameError)
                                : ""
                        }
                        error={!!personDetails?.nameError}
                    />
                    <TextField
                        data-testid="insight-session-request-personEmail"
                        required={personDetails?.shouldSendEmail}
                        margin="dense"
                        id="name"
                        name="email"
                        label={t("personEmail")}
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={personDetails?.email}
                        onChange={(event) =>
                            handleSetEmail(event.currentTarget.value)
                        }
                        helperText={
                            personDetails && personDetails.emailError
                                ? t(personDetails.emailError)
                                : ""
                        }
                        error={!!personDetails?.emailError}
                    />
                    <FormControlLabel
                        data-testid="insight-session-request-shouldSendEmail"
                        control={
                            <Switch
                                onChange={(event) =>
                                    handleShouldSendEmail(
                                        event.currentTarget.checked
                                    )
                                }
                            />
                        }
                        label={t("sendConsentLinkByMail")}
                    />
                </DialogContent>
                <DialogActions>
                    <NuxButton
                        data-testid="cancel-create-insight-session-request"
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                    >
                        {t("dialogCancel")}
                    </NuxButton>
                    <NuxButton
                        data-testid="do-create-insight-session-request"
                        disabled={invalid}
                        color="success"
                        onClick={handleCreateRequest}
                        variant="contained"
                    >
                        {t("createRequest")}
                    </NuxButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
