import React from "react";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InventoryIcon from "@mui/icons-material/Inventory";

export type IconProps = {
    size?: "inherit" | "large" | "medium" | "small";
    color?:
        | "inherit"
        | "action"
        | "disabled"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning";
};

export const OngoingIcon = ({ size, color }: IconProps) => (
    <AutorenewIcon fontSize={size || "medium"} color={color || "inherit"} />
);

export const CompletedIcon = ({ size, color }: IconProps) => (
    <ThumbUpIcon fontSize={size || "medium"} color={color || "inherit"} />
);

export const ErrorIcon = ({ size }: IconProps) => (
    <ErrorOutlineIcon fontSize={size || "medium"} color="error" />
);

export const ArchivedIcon = ({ size }: IconProps) => (
    <InventoryIcon fontSize={size || "medium"} color="secondary" />
);
