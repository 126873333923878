import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

export type NuxMessageUsages = {
    showMessage: (message: string) => void;
};

const NuxMessageContext = createContext<NuxMessageUsages>({
    showMessage: (message: string) => {},
});

interface NuxMessageContextProviderProps {
    children: React.ReactNode[] | React.ReactNode;
}

export const NuxMessageContextProvider: FC<NuxMessageContextProviderProps> = ({
    children,
}) => {
    const [message, setMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (message)
            setTimeout(() => {
                setMessage(undefined);
            }, 5000);
    }, [message]);

    return (
        <NuxMessageContext.Provider
            value={{
                showMessage: (msg) => setMessage(msg),
            }}
        >
            {message && (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                >
                    {message}
                </Alert>
            )}

            {children}
        </NuxMessageContext.Provider>
    );
};

export const useNuxMessages = () => {
    return useContext(NuxMessageContext);
};
