import React from "react";

interface FimentoFrameProps {
    visualisationLink: string;
    header: boolean;
}

const FimentoFrame = ({ visualisationLink, header }: FimentoFrameProps) => {
    return (
        <div
            style={{
                position: "absolute",
                left: "0",
                right: "0",
                bottom: "0",
                top: header ? "180px" : "50px",
            }}
        >
            {visualisationLink && (
                <iframe
                    src={visualisationLink}
                    style={{ height: "100%", width: "100%" }}
                    title="Visualisation"
                ></iframe>
            )}
        </div>
    );
};

export default FimentoFrame;
