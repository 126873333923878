import { SearchStatus } from "../SearchParameters";
import { PersonDetails } from "./PersonDetails";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../nux/niam/auth";
import {
    FormControl,
    Paper,
    Select,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NuxSearchField from "../../../nux/components/buttons/NuxSearchField";
import Box from "@mui/material/Box";
import { CreateNewAccountInsightSessionDialog } from "./CreateNewAccountInsightSessionDialog";
import MenuItem from "@mui/material/MenuItem";
import {
    ArchivedIcon,
    CompletedIcon,
    ErrorIcon,
    OngoingIcon,
} from "../../../icons/icons";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";

type SearchBarProps = {
    selectedSearchStatus: SearchStatus;
    selectedCreatedBy: string | null;
    onChangSearchText: (text: string) => void;
    onChangeSearchStatus: (searchStatus: SearchStatus) => void;
    onChangeCreatedBy: (createdBy: string | null) => void;
    onRefresh: () => void;
    createConsentRequest: (person: PersonDetails) => Promise<void>;
};
export const SearchBar = ({
    selectedSearchStatus,
    selectedCreatedBy,
    onChangSearchText,
    onChangeSearchStatus,
    onChangeCreatedBy,
    onRefresh,
    createConsentRequest,
}: SearchBarProps) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const theme = useTheme();
    const isSmOrXs = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Paper sx={{ padding: "10px", mb: "20px" }}>
            <Grid
                container
                spacing={0}
                columnSpacing={4}
                sx={{ marginLeft: "00px", marginRight: "00px" }}
            >
                <Grid xs={6}>
                    <NuxSearchField
                        onSearch={(searchText) => onChangSearchText(searchText)}
                    />
                </Grid>
                <Grid xs={6} sx={{ mt: "7px" }}>
                    <Box display="flex" justifyContent="flex-end">
                        <CreateNewAccountInsightSessionDialog
                            onCreateNewAccountInsightSession={
                                createConsentRequest
                            }
                        />
                    </Box>
                </Grid>

                <Grid xs={11} sx={{ mt: 3, mb: 1 }}>
                    <Box display="flex" alignItems="center">
                        <Stack
                            direction={isSmOrXs ? "column" : "row"}
                            spacing={isSmOrXs ? 2 : 3}
                        >
                            <FormControl size="small" sx={{ minWidth: 220 }}>
                                <Select<SearchStatus>
                                    id="status-select"
                                    value={
                                        selectedSearchStatus || SearchStatus.ALL
                                    }
                                    onChange={(e) => {
                                        onChangeSearchStatus(
                                            e.target.value as SearchStatus
                                        );
                                    }}
                                >
                                    <MenuItem value={SearchStatus.ALL}>
                                        {t("showAll")}
                                    </MenuItem>
                                    <MenuItem value={SearchStatus.WAITING}>
                                        <OngoingIcon />
                                        &nbsp;{t("ongoing")}
                                    </MenuItem>
                                    <MenuItem value={SearchStatus.COMPLETED}>
                                        <CompletedIcon color="primary" />
                                        &nbsp;{t("completed")}
                                    </MenuItem>
                                    <MenuItem value={SearchStatus.FAILED}>
                                        <ErrorIcon />
                                        &nbsp;{t("failed")}
                                    </MenuItem>
                                    <MenuItem value={SearchStatus.ARCHIVED}>
                                        <ArchivedIcon />
                                        &nbsp;{t("archived")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small" sx={{ minWidth: 220 }}>
                                <Select
                                    id="createdBy-select"
                                    value={selectedCreatedBy || "ANYONE"}
                                    onChange={(e) => {
                                        let createdBy =
                                            e.target.value === "ANYONE"
                                                ? null
                                                : e.target.value;
                                        onChangeCreatedBy(createdBy);
                                    }}
                                >
                                    <MenuItem value={"ANYONE"}>
                                        {t("createdBy")}:&nbsp;{t("Anyone")}
                                    </MenuItem>
                                    <MenuItem value={user?.sub}>
                                        {t("createdBy")}:&nbsp;{t("Me")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Box>
                </Grid>
                <Grid xs={1} sx={{ mt: 3, mb: 1 }}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton
                            color="primary"
                            aria-label="refresh"
                            onClick={() => onRefresh()}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};
