import {
    CollectorSessionBrief,
    CollectorSessionResponse,
} from "../../client/AccountInsightsTypes";

export type SearchParameters = {
    refresh: boolean;
    list: CollectorSessionBrief[];
    text: string;
    selectedStatus: SearchStatus;
    createdBy: string | null;
    currentPage: number;
    sessionsPerPage: number;
    totalSessions: number;
    showArchived: boolean;
};

export enum SearchStatus {
    ALL = "ALL",
    WAITING = "WAITING",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    ARCHIVED = "ARCHIVED",
}

export function updateWithCollectorSessionResponse(
    body: CollectorSessionResponse
) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: body.list,
            text: currentSearchParameter.text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: currentSearchParameter.currentPage,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: body.total,
            showArchived: currentSearchParameter.showArchived,
        };
    };
}

export function searchForText(text: string) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: [],
            text: text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: 1,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: 0,
            showArchived: currentSearchParameter.showArchived,
        };
    };
}

export const searchForSelectedStatus =
    (selectedStatus: SearchStatus | null) =>
    (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: [],
            text: currentSearchParameter.text,
            selectedStatus: selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: 1,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: 0,
            showArchived: currentSearchParameter.showArchived,
        };
    };

export function searchForCreatedBy(createdBy: string | null) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: [],
            text: currentSearchParameter.text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: createdBy,
            currentPage: 1,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: 0,
            showArchived: currentSearchParameter.showArchived,
        };
    };
}

export function searchForShowArchived(showArchived: boolean) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: [],
            text: currentSearchParameter.text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: 1,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: 0,
            showArchived: showArchived,
        };
    };
}

export function updateSessionsPerPage(sessionsPerPage: number) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: [],
            text: currentSearchParameter.text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: currentSearchParameter.currentPage,
            sessionsPerPage: sessionsPerPage,
            totalSessions: currentSearchParameter.totalSessions,
            showArchived: currentSearchParameter.showArchived,
        };
    };
}

export function updateCurrentPage(currentPage: number) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: false,
            list: [],
            text: currentSearchParameter.text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: currentPage,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: currentSearchParameter.totalSessions,
            showArchived: currentSearchParameter.showArchived,
        };
    };
}

export function refreshSearch(refresh: boolean) {
    return (currentSearchParameter: SearchParameters) => {
        return {
            refresh: refresh,
            list: currentSearchParameter.list,
            text: currentSearchParameter.text,
            selectedStatus: currentSearchParameter.selectedStatus,
            createdBy: currentSearchParameter.createdBy,
            currentPage: currentSearchParameter.currentPage,
            sessionsPerPage: currentSearchParameter.sessionsPerPage,
            totalSessions: currentSearchParameter.totalSessions,
            showArchived: currentSearchParameter.showArchived,
        };
    };
}
