import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import { FormControl, Pagination, Select, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

type SearchPaginationProps = {
    sessionsPerPage: number;
    currentPage: number;
    currentCount: number;
    onChangeSessionsPerPage: (sessionsPerPage: number) => void;
    onChangeCurrentPage: (currentPage: number) => void;
};
export const SearchPagination = ({
    sessionsPerPage,
    currentPage,
    currentCount,
    onChangeSessionsPerPage,
    onChangeCurrentPage,
}: SearchPaginationProps) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginTop: "10px" }}
        >
            <Grid xs={12} sm={4}>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginRight: 1,
                            paddingTop: "2px",
                        }}
                    >
                        {t("sessionsPerPage")}:
                    </Typography>
                    <FormControl size="small">
                        <Select
                            id="sessions-per-page-select"
                            value={sessionsPerPage}
                            onChange={(e) => {
                                onChangeSessionsPerPage(Number(e.target.value));
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid xs={12} sm={8}>
                <Pagination
                    count={currentCount}
                    page={currentPage}
                    onChange={(event, value) =>
                        onChangeCurrentPage(Number(value))
                    }
                    color="primary"
                />
            </Grid>
        </Grid>
    );
};
