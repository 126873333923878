import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ButtonPropsVariantOverrides,
    SxProps,
    Theme,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import {
    ButtonPropsColorOverrides,
    ButtonPropsSizeOverrides,
} from "@mui/material/Button/Button";

export interface NuxButtonProps {
    children: ReactNode;
    onClick: () => void;
    size?: OverridableStringUnion<
        "small" | "medium" | "large",
        ButtonPropsSizeOverrides
    >;
    color?: OverridableStringUnion<
        | "inherit"
        | "primary"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning",
        ButtonPropsColorOverrides
    >;
    startIcon?: React.ReactNode;
    sx?: SxProps<Theme>;
    variant?: OverridableStringUnion<
        "text" | "outlined" | "contained",
        ButtonPropsVariantOverrides
    >;
    disabled?: boolean;
    disableElevation?: boolean;
    disableFocusRipple?: boolean;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
}

const NuxButton = (nuxButtonProps: NuxButtonProps) => {
    const { t } = useTranslation();
    return <Button {...nuxButtonProps}>{nuxButtonProps.children}</Button>;
};

export default NuxButton;
