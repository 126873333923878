import React, { FC, useEffect, useRef, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useIdleTimer } from "react-idle-timer";
import { useAuth } from "./auth";

export interface TokenAboutToExpireAlertProps {
    idleTimeoutInSeconds: number;
    countdownTimeInSeconds: number;
}

const IdleAlert: FC<TokenAboutToExpireAlertProps> = ({
    idleTimeoutInSeconds,
    countdownTimeInSeconds,
}) => {
    const [isIdle, setIsIdle] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(countdownTimeInSeconds);
    const countdownRef = useRef<NodeJS.Timeout | null>(null);

    const auth = useAuth();

    useEffect(() => {
        return () => {
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
        };
    }, []);

    const onIdle = () => {
        setIsIdle(true);

        setSecondsLeft(countdownTimeInSeconds);
        countdownRef.current = setInterval(() => {
            setSecondsLeft((prev) => getTimeLeft(prev));
        }, 1000);
    };

    const onActive = () => {
        setIsIdle(false);
        stopCountdown();
    };

    function getTimeLeft(prev: number) {
        if (prev > 1) {
            return prev - 1;
        }

        console.log("User inactive, logging out.");
        stopCountdown();
        auth.logout();
        return 0;
    }

    const stopCountdown = () => {
        if (countdownRef.current) {
            clearInterval(countdownRef.current);
            countdownRef.current = null;
        }
    };

    useIdleTimer({
        timeout: idleTimeoutInSeconds * 1000,
        onIdle,
        onActive,
        debounce: 500,
    });

    return (
        <Snackbar
            open={isIdle}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert
                severity="warning"
                sx={{ width: "100%", vertical: "bottom", horizontal: "right" }}
            >
                {`Du loggas ut om ${Math.floor(secondsLeft / 60)} minuter och ${
                    secondsLeft % 60
                } sekunder pga inaktivitet`}
            </Alert>
        </Snackbar>
    );
};

export default IdleAlert;
