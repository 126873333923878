import { AccessTokenResponse, UserInfoResponse } from "./NiamAuthTypes";

const getAuthHeader = (accessToken: string | null): { [key: string]: string } =>
    accessToken
        ? {
              Authorization: `Bearer ${accessToken}`,
          }
        : {};

export const fetchAuthWebAccessToken = async (
    tokenUrl: string,
    code: string,
    clientId: string,
    codeVerifier: string,
    redirectUrl: string
): Promise<AccessTokenResponse | null> => {
    const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        },
        body: new URLSearchParams({
            grant_type: "authorization_code",
            code: code,
            client_id: clientId,
            code_verifier: codeVerifier,
            redirect_uri: redirectUrl,
        }),
    });
    if (!response.ok) {
        switch (response.status) {
            case 400:
                const body = await response.json();
                throw new Error(body.error);
        }
        console.error("fetchAuthWebAccessToken failed", response);
        return null;
    }
    const body = await response.json();
    return body as AccessTokenResponse;
};

export const fetchRefreshedAccessToken = async (
    tokenUrl: string,
    refreshToken: string,
    clientId: string
) => {
    const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            client_id: clientId,
        }),
    });
    if (response.status === 200) {
        return (await response.json()) as AccessTokenResponse;
    }
    throw new Error("error occured when refreshing token");
};

export const fetchAuthUser = async (
    userInfoUrl: string,
    accessToken: string
): Promise<UserInfoResponse | null> => {
    try {
        const response = await fetch(userInfoUrl, {
            headers: getAuthHeader(accessToken),
        });
        if (response.status === 200) {
            const body = await response.json();
            return body as UserInfoResponse;
        }
    } catch (e) {
        console.error(e);
    }
    return null;
};

export const fetchAuthEnrichedAccessToken = async (
    tokenUrl: string,
    clientId: string,
    username: string,
    password: string
): Promise<AccessTokenResponse | null> => {
    try {
        const response = await fetch(tokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
            },
            body: new URLSearchParams({
                grant_type: "password",
                client_id: clientId,
                username: username,
                password: password,
            }),
        });
        const body = await response.json();
        return body as AccessTokenResponse;
    } catch (e) {
        console.error(e);
    }
    return null;
};

export const fetchAuthInternalAccessToken = async (
    tokenUrl: string,
    clientId: string,
    clientSecret: string,
    username: string,
    password: string
): Promise<AccessTokenResponse | null> => {
    try {
        const response = await fetch(tokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
            },
            body: new URLSearchParams({
                grant_type: "password",
                client_id: clientId,
                client_secret: clientSecret,
                username: username,
                password: password,
            }),
        });
        const body = await response.json();
        return body as AccessTokenResponse;
    } catch (e) {
        console.error(e);
    }
    return null;
};
