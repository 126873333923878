import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FimentoFrame from "../../../components/FimentoFrame";
import React from "react";

type ShowVisualisationLinkDialog = {
    visualisationLink: string | undefined;
    setVisualisationLink: (visualisationLink: string | undefined) => void;
};
export const ShowVisualisationLinkDialog = ({
    visualisationLink,
    setVisualisationLink,
}: ShowVisualisationLinkDialog) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={() => setVisualisationLink(undefined)}
            open={visualisationLink != null}
            fullScreen={true}
        >
            <DialogTitle>{t("visualization")}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setVisualisationLink(undefined)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {visualisationLink && (
                    <FimentoFrame
                        visualisationLink={visualisationLink}
                        header={false}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
